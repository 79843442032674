import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import Layout from "../components/Layout"
import { FileEarmarkX } from "react-bootstrap-icons"
import Seo from "../components/Seo"

export const Head = () => {
  return <Seo customTitle="Fehler 404" />
}

export default function notFound() {
  return (
    <Layout>
      <Container className="my-5 text-center">
        <h1 className="display-1 font-weight-bold">
          <FileEarmarkX className="d-block mx-auto text-primary" />
          Fehler 404
        </h1>
        <p className="h2 my-5">
          Die Seite oder Datei konnte auf dem Server nicht gefunden werden!
        </p>
        <p className="lead font-weight-normal lh-text text-dark">
          Möglicherweise wurde die Seite oder Datei verschoben, gelöscht oder
          Sie haben sich vertippt. Letztendlich konnten wir die Seite oder Datei
          nicht finden. Benutzen Sie bitte einen der folgenden Links, um zurück
          zur Webseite zu gelangen.
        </p>
        <p className="font-weight-bold mt-3">
          Wir würden uns freuen, wenn Sie diesen Fehler per E-Mail melden
          könnten.
        </p>
        <ButtonGroup className="mt-5" aria-label="Links">
          <Link className="text-decoration-none" to="/">
            <Button className="border-radius-r-0" variant="primary">
              Zurück zur Startseite...
            </Button>
          </Link>
          <Link className="text-decoration-none" to="/kontakt">
            <Button className="border-radius-l-0" variant="outline-primary">
              Fehler melden...
            </Button>
          </Link>
        </ButtonGroup>
      </Container>
    </Layout>
  )
}
